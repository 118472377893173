import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";

import { Button } from "react-bootstrap";

const Page404 = () => (
  <React.Fragment>
    <Helmet title="409 Error" />
    <Container fluid className="p-0">
      <div className="text-center">
        <h1 className="display-1 fw-bold">409</h1>
        <p className="h1">Not Authorized!</p>
        <p className="h2 fw-normal mt-3 mb-4">
          You are not authorized to access this page.
        </p>
        {/* <Link to="/dashboard/default">
        <Button variant="primary" size="lg">
          Return to website
        </Button>
      </Link> */}
      </div>
    </Container>
  </React.Fragment>
);

export default Page404;
