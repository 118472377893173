import React, { useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  Alert,
  Button,
} from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";

const Pruning = () => {
  const [modalState, setModalState] = useState({
    modalOpen: false,
    excelColumns: [""],
    fileData: null,
  });
  const closeModal = () => {
    setModalState({ modalOpen: false });
  };
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = (e) => {
        // Do whatever you want with the file contents
        const wb = XLSX.read(reader.result, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        console.log("File: ", data);
        const allKeys = Object.keys(data[0]);
        //update the state
        const newState = modalState;
        console.log("Current state: modal should be true", newState);
        newState.excelColumns = allKeys;
        newState.modalOpen = true;
        newState.fileData = file;
        setModalState({ ...newState });
        console.log("Xlsx columns: ", allKeys);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx",
    maxFiles: 1,
  });
  return (
    <React.Fragment>
      <Helmet title="Prune contacts" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Pruning</h1>
        <Card>
          <Card.Header tag="h5">Prune Contact List</Card.Header>
          <Card.Body>
            <Formik
              initialValues={{
                the_file: "",
                phone_number_column: "",
              }}
              validationSchema={Yup.object().shape({
                phone_number_column: Yup.string().required(
                  "Specify the column containing phonenumbers"
                ),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                console.log("Prune contacts form: ", values);
                const fd = new FormData();
                fd.append("the_file", modalState.fileData);
                fd.append("phone_number_column", values.phone_number_column);
                fd.append("phone_number_prefix", values.phone_number_prefix);
                try {
                  const response = await fetch(
                    "http://127.0.0.1:8000/api/administration/prune-contacts",
                    {
                      method: "POST",
                      body: fd,
                    }
                  );
                  console.log("Response: ", response);
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}
                  <Row>
                    <Col md={5}>
                      <Form.Group className="mb-3">
                        <Form.Label>File</Form.Label>
                        <div {...getRootProps()} className="bg-light p-3 ">
                          <input {...getInputProps()} />
                          {modalState.fileData == null ? (
                            <p className="text-center">
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          ) : (
                            <p className="text-center">
                              {modalState.fileData.name}
                            </p>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Select phone number column</Form.Label>
                        <Form.Select
                          size="lg"
                          type="text"
                          name="phone_number_column"
                          isInvalid={Boolean(
                            touched.phone_number_column &&
                              errors.phone_number_column
                          )}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option></option>
                          {modalState.excelColumns !== undefined
                            ? modalState.excelColumns.map((col) => (
                                <option key={col} value={col}>
                                  {col}
                                </option>
                              ))
                            : null}
                        </Form.Select>
                        {!!touched.phone_number_column && (
                          <Form.Control.Feedback type="invalid">
                            {errors.phone_number_column}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Phonenumber prefix</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          name="phone_number_prefix"
                          isInvalid={Boolean(
                            touched.phone_number_prefix &&
                              errors.phone_number_prefix
                          )}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {!!touched.phone_number_prefix && (
                          <Form.Control.Feedback type="invalid">
                            {errors.phone_number_prefix}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Button
                        className="mt-4"
                        type="submit"
                        variant="primary"
                        size="lg"
                        disabled={isSubmitting}
                      >
                        Prune
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Prune History</Card.Header>
          <Card.Body>Pruning History Table</Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Pruning;
