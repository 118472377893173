import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button } from "react-bootstrap";

import { Link } from "react-router-dom";

const EmailVerified = () => {
  return (
    <React.Fragment>
      <Helmet title="Email Verified" />
      <div className="text-center mt-4">
        <h2>Successfully verified your email</h2>
        <p className="lead">
          <Link to={"/auth/sign-in"}>Go to Login</Link>
        </p>
      </div>
    </React.Fragment>
  );
};

export default EmailVerified;
