import React from "react";
import { Card } from "react-bootstrap";
import FullTable from "../../../../ui/tables/FullTable";
import { Trash } from "react-feather";

const Results = () => {
  const columns = [
    {
      Header: "#",
      accessor: "idd", // accessor is the "key" in the data
      disableSortby: true,
    },

    {
      Header: "Filename",
      accessor: "file_name",
    },
    {
      Header: "Testing Phone number",
      accessor: "name",
    },
    {
      Header: "Succesful",
      accessor: "succesful",
      filter: "includes",
    },
    {
      Header: "Failed",
      accessor: "failed",
      filter: "includes",
    },
    {
      Header: "User",
      accessor: "user",
    },

    {
      Header: "Actions",
      accessor: "id",
      disableSortby: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex flex-row justify-content-around w-75">
            <div className="p-1 m-3">
              <Trash className="m-3" color="#FFA4A1" size={24} />
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">Job results</Card.Title>
      </Card.Header>
      <Card.Body>
        <FullTable values={[]} cols={columns} />
      </Card.Body>
    </Card>
  );
};
export default Results;
