import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const smsTablesApi = createApi({
  reducerPath: "smsTablesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "http://127.0.0.1:8000/api/",
    mode: "no-cors",
  }),
  tagTypes: ["AnniversarySmsList"],
  endpoints: (builder) => ({
    getAllAnniversarySmsList: builder.query({
      query: (account_id) => `anniversary-sms/${account_id}`,
      transformResponse: (response, meta, arg) => response.status,
      providesTags: ["AnniversarySmsList"],
    }),
    addAnniversarySmsList: builder.mutation({
      query: (anniversarySms) => ({
        url: "anniversary-sms",
        method: "POST",
        body: anniversarySms,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
      invalidatesTags: ["AnniversarySmsList"],
    }),
  }),
});

export const {
  useGetAllAnniversarySmsListQuery,
  useAddAnniversarySmsListMutation,
} = smsTablesApi;
