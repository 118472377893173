import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Formik, Field } from "formik";
import { useDropzone } from "react-dropzone";
import {
  useAddPeriodicSMSMutation,
  useDeletePeriodicSMSMutation,
} from "../../../../redux/slices/accounts";
import NotyfContext from "../../../../contexts/NotyfContext";
import * as XLSX from "xlsx";
import FullTable from "../../../ui/tables/FlatSimpleTable";
import {
  Card,
  Container,
  Button,
  Form,
  Alert,
  Modal,
  CloseButton,
} from "react-bootstrap";
import axios from "axios";
import * as Yup from "yup";
import { useGetAllPeriodicSMSQuery } from "../../../../redux/slices/accounts";

const PeriodicSms = () => {
  const notyf = useContext(NotyfContext);
  const [addPeriodicSMS] = useAddPeriodicSMSMutation();
  const [deletePeriodicSMS] = useDeletePeriodicSMSMutation();

  let datas = null;
  const [modalState, setModalState] = useState({
    modalOpen: false,
    excelColumns: [""],
    fileData: null,
  });
  const { data = [], error, isLoading } = useGetAllPeriodicSMSQuery();
  const [colsList, setColsList] = useState([]);

  if (error) {
    console.log("Error occurred getting lists", error);
  }
  const closeModal = () => {
    setModalState({ modalOpen: false });
  };

  const readExcelFile = (file, setFieldValue) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (err) => {
        reject(err);
      };
    });

    promise.then((data) => {
      const cols = Object.keys(data[0]);
      setFieldValue("colsList", cols);
      setColsList(cols);
    });
  };
  const onRequestButtonClicked = () => {
    setModalState({ modalOpen: true });
  };

  let form = modalState.modalOpen ? (
    <Modal show={true}>
      <Modal.Header className="d-flex flex-row justify-content-between">
        <div>
          <h2>Create new Periodic SMS</h2>
        </div>
        <div>
          <CloseButton onClick={closeModal} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            group_name: "",
            group_description: "",
            sender_id: "INFO",
            message: "",
            send_time: "",
            phonenumber_column: "",
            birth_date_column: "",
            the_file: "",
            colsList: [],
          }}
          validationSchema={Yup.object().shape({
            group_name: Yup.string().required("Name of group is required"),
            group_description: Yup.string().required(
              "Please provide a description"
            ),
            sender_id: Yup.string().required("Sender ID is required"),
            message: Yup.string().required("A message is required"),
            send_time: Yup.string(),
            phonenumber_column: Yup.string().required(
              "Must select the column containing phonenumbers"
            ),
            birth_date_column: Yup.string().required(
              "Must select the column containing birth dates"
            ),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              console.log("Perodic SMS ModalForm values: ", values);
              console.log("The file: ", modalState.fileData);
              const fd = new FormData();
              fd.append("group_name", values.group_name);
              fd.append("group_description", values.group_description);
              fd.append("sender_id", values.sender_id);
              fd.append("message", values.message);
              fd.append("send_time", values.send_time);
              fd.append("phone_number_column", values.phonenumber_column);
              fd.append("birth_date_column", values.birth_date_column);
              fd.append("theFile", values.the_file);
              const response = await addPeriodicSMS(fd);
              if (response.status === 200) {
                notyf.success("Succesfully Submitted!");
                closeModal();
              }

              console.log("Response from server: ", response);
            } catch (e) {
              console.log("Periodic SMS ModalForm Error: ", e);
              notyf.error("Error Submitting! Check connection");
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}
              <p className="text-primary text-center">
                Upload a excel file containing phone number and the anniversary
                date
              </p>
              <Form.Group className="mb-3">
                <Form.Label>Group name</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="group_name"
                  placeholder="Eg. Birthday list"
                  value={values.group_name}
                  isInvalid={Boolean(touched.group_name && errors.group_name)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.group_name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.group_name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Group description</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="group_description"
                  placeholder=""
                  value={values.group_description}
                  isInvalid={Boolean(
                    touched.group_description && errors.group_description
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.group_description && (
                  <Form.Control.Feedback type="invalid">
                    {errors.group_description}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Sender id</Form.Label>
                <Form.Select
                  size="lg"
                  type="text"
                  name="sender_id"
                  isInvalid={Boolean(touched.sender_id && errors.sender_id)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <option value="INFO">INFO</option>
                </Form.Select>
                {!!touched.sender_id && (
                  <Form.Control.Feedback type="invalid">
                    {errors.sender_id}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>File</Form.Label>
                <Form.Control
                  name="the_file"
                  onChange={(event) => {
                    setFieldValue("the_file", event.currentTarget.files[0]);
                    setFieldValue("openModal", true);
                    readExcelFile(event.currentTarget.files[0], setFieldValue);
                  }}
                  type="file"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Select phone number column</Form.Label>
                <Form.Select
                  size="lg"
                  type="text"
                  name="phonenumber_column"
                  isInvalid={Boolean(
                    touched.phonenumber_column && errors.phonenumber_column
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {colsList.map((col) => (
                    <option key={col} value={col}>
                      {col}
                    </option>
                  ))}
                </Form.Select>
                {!!touched.phonenumber_column && (
                  <Form.Control.Feedback type="invalid">
                    {errors.phonenumber_column}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Select column containing Birth dates</Form.Label>
                <Form.Select
                  size="lg"
                  type="text"
                  name="birth_date_column"
                  isInvalid={Boolean(
                    touched.birth_date_column && errors.birth_date_column
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {colsList.map((col) => (
                    <option key={col} value={col}>
                      {col}
                    </option>
                  ))}
                </Form.Select>
                {!!touched.birth_date_column && (
                  <Form.Control.Feedback type="invalid">
                    {errors.birth_date_column}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label>Insert Excel/CSV Columns</Form.Label>
                <Form.Select
                  onBlur={handleBlur}
                  value={values.message}
                  onChange={(e) => {
                    setFieldValue(
                      "message",
                      `${values.message}${e.target.value}`
                    );
                  }}
                  name="smsExcelCsvColumns"
                >
                  <option />
                  {colsList.map((col) => (
                    <option value={`#${col}`}>{col}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  size="lg"
                  type="text"
                  name="message"
                  placeholder=""
                  value={values.message}
                  isInvalid={Boolean(touched.message && errors.message)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <p>Characters: {values.message.length}</p>
                {!!touched.message && (
                  <Form.Control.Feedback type="invalid">
                    {errors.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Send time</Form.Label>
                <Field name="send_time">
                  {({
                    field, // { name, value, onChange, onBlur }

                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.

                    meta,
                  }) => (
                    <div>
                      <input
                        className="form-control"
                        type="time"
                        placeholder="Email"
                        {...field}
                      />

                      {/* {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )} */}
                    </div>
                  )}
                </Field>
                {!!touched.send_time && (
                  <Form.Control.Feedback type="invalid">
                    {errors.send_time}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <div className="text-center mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  ) : null;

  const columns = [
    {
      Header: "Group name",
      accessor: "group_name",
    },
    {
      Header: "Description",
      accessor: "group_description",
    },
    {
      Header: "Sender ID",
      accessor: "sender_id",
    },
    {
      Header: "Send time",
      accessor: "send_time",
    },
    {
      Header: "Message",
      accessor: "message",
    },
    {
      Header: "Created",
      accessor: "created_at",
    },
    {
      Header: "Action",
      accessor: "id",
      Cell: ({ value }) => {
        return (
          <Button
            onClick={() => deletePeriodicSMS({ id: value })}
            variant="danger"
          >
            Delete
          </Button>
        );
      },
    },
  ];

  if (!isLoading) {
    console.log("Data: ", data);
    datas = data;
    console.log("Datas: ", datas);
  }

  return (
    <React.Fragment>
      {form}
      <Helmet title="SMS Sender ID" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Anniversary SMS</h1>
        <Card>
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <div>
                <Card.Title tag="h5">Anniversary SMS</Card.Title>
                <h6 className="card-subtitle mb-3 text-muted">
                  Send Anniversary wishes to your target audience eg. Birtday
                  wishes, celebrate the day they joined etc.
                </h6>
              </div>
              <div>
                <Button
                  variant="success"
                  onClick={onRequestButtonClicked}
                  size="lg"
                >
                  Upload Anniversary File
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <FullTable values={data} cols={columns} />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default PeriodicSms;
