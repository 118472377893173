import React, { useEffect, useState } from "react";
import { Row, Table, Col, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import FullTable from "../../../../ui/tables/FullTable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useGetAllAccountsQuery } from "../../../../../redux/slices/accounts";
const NewAccountsTable = () => {
  const navigate = useNavigate();
  var rows = [];
  const { data = [], error, isLoading } = useGetAllAccountsQuery();
  if (error === 401) {
    navigate("/admin/401");
  }
  if (!isLoading) {
    rows = data;
    console.log("All accounts: ", data);
  }

  const columns = [
    {
      Header: "Company name",
      accessor: "company_name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Company email",
      accessor: "company_email",
    },
    {
      Header: "Support email",
      accessor: "support_email",
    },
    {
      Header: "Billing email",
      accessor: "billing_email",
    },
    {
      Header: "Phone number",
      accessor: "phone_number",
    },
    {
      Header: "Created at",
      accessor: "created_at",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value, row }) => {
        if (value === "Activated") {
          return <strong className="text-success">Activated</strong>;
        } else if (value === "Trial") {
          return <span>Trial</span>;
        } else if (value === "Requests Activation") {
          return (
            <Link
              to={`/admin/manage_accounts/activate/${row.original.id}`}
              className="text-warning"
            >
              Requests Activation
            </Link>
          );
        } else {
          return <span className="text-danger">Unknown</span>;
        }
      },
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <FullTable values={rows} cols={columns} />
      </Row>
    </React.Fragment>
  );
};

export default NewAccountsTable;
