import React from "react";
import { Outlet } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import { useSelector } from "react-redux";

import dashboardItems from "../components/sidebar/dashboardItems";
import airtimeItems from "../components/sidebar/airtimeItems";
const Dashboard = ({ children }) => {
  const currentService = useSelector((state) => state.sema_services.value);
  const items = currentService.items;
  var loadedItems = null;
  switch (items) {
    case "dashboardItems":
      loadedItems = dashboardItems;
      break;
    case "airtimeItems":
      loadedItems = airtimeItems;
      break;
    default:
      loadedItems = dashboardItems;
  }
  console.log("Dashboard layout: ", currentService);
  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar items={loadedItems} />
        <Main>
          <Navbar />
          <Content>
            {children}
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </Wrapper>
      <Settings />
    </React.Fragment>
  );
};

export default Dashboard;
