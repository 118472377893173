import React from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { AlertTriangle } from "react-feather";
import DashboardLayout from "../../../layouts/Dashboard";
import { useSelector } from "react-redux";
const AirtimeDash = () => {
  const currentService = useSelector((state) => state.sema_services.value);
  return (
    <DashboardLayout>
      <Helmet title={currentService.name} />
      <Container fluid className="p-0">
        <div className="text-center">
          <AlertTriangle size={128} />
          <br></br>
          <h1>Under Construction</h1>
          <h5>We are working on something pretty cool!</h5>
        </div>
      </Container>
    </DashboardLayout>
  );
};

export default AirtimeDash;
