import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { switchService } from "../redux/slices/serviceSlice";
import useAppDispatch from "../hooks/useAppDispatch";
import { useGetAllApiKeysQuery } from "../redux/slices/accounts";
import { useNavigate } from "react-router-dom";
// import axios from "../utils/axios";
import axios from "axios";
import AuthLayout from "../layouts/Auth";
import semaIcon from "../assets/img/sema-icon.png";
import { isValidToken, setSession } from "../utils/jwt";
import { fetchApiKeys } from "../redux/slices/accounts";
import NotyfContext from "./NotyfContext";
import { retrieveSenderIDs } from "../redux/slices/senderids";
import { Spinner } from "react-bootstrap";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const [appLoading, setAppLoading] = useState(true);
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    const initialize = async () => {
      console.log("initialzing from initialize");
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        console.log("Token from cookie: ", accessToken);
        if (accessToken) {
          setSession(accessToken);
          console.log("token found in localstorage, checking if still valid");

          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}auth/token_gets_user`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              withCredentials: true,
            }
          );

          console.log("Getting auth user", response);
          // console.log("token valid going to api");
          if (response.status === 200) {
            const { user } = response.data;
            if (user !== null || user !== undefined) {
              dispatch({
                type: INITIALIZE,
                payload: {
                  isAuthenticated: true,
                  user,
                },
              });
            }
          } else {
            console.log("the cors error");
            navigate("/auth/sign-in");
            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        } else {
          console.log("token not found or invalid");
          navigate("/auth/sign-in");
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
        const currentService = window.localStorage.getItem(
          "sema-current-service"
        );
        console.log("Current service init: ", currentService);
        if (currentService !== null) {
          appDispatch(switchService(currentService));
        }
      } catch (err) {
        console.error("Error occureed fetching current user", err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
      setAppLoading(false);
    };

    initialize();
  }, []);

  const signIn = async (email, password) => {
    // axios.defaults.withCredentials = true;
    //const csrf_res = await get_csrf();
    try {
      const fd = new FormData();
      fd.append("email", email);
      fd.append("password", password);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}loginuser`,
        null,
        {
          params: { email: email, password: password },
        }
      );

      if (response.status === 200) {
        console.log("sign in", response);

        const { accessToken, user } = response.data;
        window.localStorage.setItem("user", user);
        window.localStorage.setItem("accessToken", accessToken);
        setSession(accessToken);
        appDispatch(retrieveSenderIDs());
        dispatch({ type: SIGN_IN, payload: { user } });
        return user;
      }
      return null;
    } catch (e) {
      console.log("sign in:", e.message);
      return e.message;
    }
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (
    email,
    password,
    first_name,
    last_name,
    phone_number
  ) => {
    console.log(
      "SIGN UP VALUES: ",
      `email: ${email} - ${password} - ${first_name}/${last_name} - ${phone_number}`
    );
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}register_new_client`,
      {
        email,
        password,
        first_name,
        last_name,
        phone_number,
      }
    );
    console.log(response);
    const user = response.data;
    return user;
  };

  const resetPassword = async (email) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}forgot-password`,
      { email }
    );
    console.log("Password reset request : ", response);
    if (response.data) {
      if (response.data.msg) {
        notyf.success(response.data.msg);
        return response.data.msg;
      } else if (response.data.email) {
        notyf.error(response.data.email);
        return response.data.email;
      }
    }
  };
  if (!appLoading) {
    return (
      <AuthContext.Provider
        value={{
          ...state,
          method: "jwt",
          signIn,
          signOut,
          signUp,
          resetPassword,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
  return (
    <AuthLayout>
      <img src={semaIcon} className="w-25" alt="Sema Communications Logo" />
      <h1>Sema Communications Portal</h1>
      <div className="d-flex flex-row">
        <Spinner animation="border" role="status"></Spinner>
        <h3 className="ps-3">Loading...</h3>
      </div>
    </AuthLayout>
  );
}

export { AuthContext, AuthProvider };
