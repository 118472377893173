import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button } from "react-bootstrap";

import { Link } from "react-router-dom";

const VerifyEmailNoticePage = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { email } = location.state;
  const resendEmail = async () => {
    setLoading(true);
    const res = await fetch(`${process.env.REACT_APP_API_URL}email/resend`);
    setLoading(false);
    console.log("Res: ", res);
  };
  return (
    <React.Fragment>
      <Helmet title="Verify Email" />
      <div className="text-center mt-4">
        <h2>Verify your email</h2>
        <p className="lead">
          We've sent an email to {email}to verify your email and activate your
          account. The link in the mail will expire in 24 hours.
        </p>
        <Button
          disabled={loading ? true : false}
          onClick={resendEmail}
          variant="link"
        >
          Resend
        </Button>
      </div>
    </React.Fragment>
  );
};

export default VerifyEmailNoticePage;
