const sid_data = [
  {
    id: 1,
    country: "Tanzania",
    name: "Sema",
    status: "Active",
    registered_networks: [
      {
        vendor: "Halotel",
        halotel: "Registered",
        airtel: "Pending",
        vodacom: "Registered",
        tigo: "Registered",
        zantel: "Pending",
      },
      {
        vendor: "Airtel",
        halotel: "Registered",
        airtel: "Pending",
        vodacom: "Registered",
        tigo: "Registered",
        zantel: "Pending",
      },
      {
        vendor: "Zantel",
        halotel: "Registered",
        airtel: "Pending",
        vodacom: "Registered",
        tigo: "Registered",
        zantel: "Pending",
      },
      {
        vendor: "Vodacom",
        halotel: "Registered",
        airtel: "Pending",
        vodacom: "Registered",
        tigo: "Registered",
        zantel: "Pending",
      },
    ],
    date_created: "8/20/2019 11:48:42 AM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 2,
    country: "Tanzania",
    name: "LAINA",
    status: "Active",
    registered_networks: [
      {
        network: "Halotel",
        registerer: "Halotel",
        status: "Pending",
      },
      {
        network: "TTCL",
        registerer: "TTCL",
        status: "Registered",
      },
      {
        network: "Vodacom",
        registerer: "Vodacom",
        status: "Pending",
      },
      {
        network: "Tigo",
        registerer: "Halotel",
        status: "Pending",
      },
      {
        network: "Smile",
        registerer: "Halotel",
        status: "Registered",
      },
      {
        network: "Airtel",
        registerer: "Airtel",
        status: "Registered",
      },
      {
        network: "Airtel",
        registerer: "Vodacom",
        status: "Registered",
      },
    ],
    date_created: "8/20/2019 11:59:30 AM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 3,
    country: "Tanzania",
    name: "SUPA5",
    status: "Active",
    registered_networks: [
      {
        network: "Halotel",
        registerer: "Halotel",
        status: "Pending",
      },
      {
        network: "Vodacom",
        registerer: "Vodacom",
        status: "Pending",
      },
      {
        network: "Tigo",
        registerer: "Halotel",
        status: "Pending",
      },
      {
        network: "Smile",
        registerer: "Halotel",
        status: "Registered",
      },
      {
        network: "Airtel",
        registerer: "Airtel",
        status: "Registered",
      },
    ],
    date_created: "8/20/2019 1:47:56 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 4,
    country: "Tanzania",
    name: "SYNQSMS",
    status: "Active",
    registered_networks: [
      {
        network: "Halotel",
        registerer: "Halotel",
        status: "Pending",
      },
      {
        network: "Vodacom",
        registerer: "Vodacom",
        status: "Pending",
      },
      {
        network: "Tigo",
        registerer: "Halotel",
        status: "Pending",
      },
      {
        network: "Smile",
        registerer: "Smile",
        status: "Registered",
      },
      {
        network: "Airtel",
        registerer: "Airtel",
        status: "Registered",
      },
    ],
    date_created: "8/20/2019 1:48:30 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 5,
    country: "Tanzania",
    name: "PremierBet",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Not Allowed" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Pending" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Registered" },
    ],
    date_created: "8/20/2019 1:49:11 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 6,
    country: "Tanzania",
    name: "StanChart",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 1:49:40 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 7,
    country: "Tanzania",
    name: "Barclays",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 1:50:13 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 8,
    country: "Tanzania",
    name: "SMSALA",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 1:51:27 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 9,
    country: "Tanzania",
    name: "TaESA",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Registered" },
      { name: "TTCL", status: "Registered" },
      { name: "Smile", status: "Registered" },
    ],
    date_created: "8/20/2019 1:51:40 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 10,
    country: "Tanzania",
    name: "REDTAG",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 1:52:09 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 11,
    country: "Tanzania",
    name: "BRAIWEB",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 1:52:26 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 12,
    country: "Tanzania",
    name: "INFO",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 1:52:38 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 13,
    country: "Tanzania",
    name: "MOBISOL",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 1:53:46 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 14,
    country: "Tanzania",
    name: "DISTRO",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 1:54:26 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 15,
    country: "Tanzania",
    name: "ALERT",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 1:54:41 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 16,
    country: "Tanzania",
    name: "FINBET",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 1:55:01 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 17,
    country: "Tanzania",
    name: "HELAGAMES",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 1:55:18 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 18,
    country: "Tanzania",
    name: "Tatu3",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:01:59 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 19,
    country: "Tanzania",
    name: "MeTL BAJAJ",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:02:24 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 20,
    country: "Tanzania",
    name: "SMILE FM",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:03:03 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 21,
    country: "Tanzania",
    name: "WILMART",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:03:17 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 22,
    country: "Tanzania",
    name: "WeChat",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:03:33 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 23,
    country: "Tanzania",
    name: "PING",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:04:17 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 24,
    country: "Tanzania",
    name: "DHL",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:04:31 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 25,
    country: "Tanzania",
    name: "UNILEVER",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:05:12 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 26,
    country: "Tanzania",
    name: "Aramex",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:05:34 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 27,
    country: "Tanzania",
    name: "Meridian",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:05:50 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 28,
    country: "Tanzania",
    name: "Fastjet",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:06:23 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 29,
    country: "Tanzania",
    name: "LINE",
    status: "Inactive",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:06:36 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 30,
    country: "Tanzania",
    name: "Badoo",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:06:50 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 31,
    country: "Tanzania",
    name: "STANBIC",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:07:07 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 32,
    country: "Tanzania",
    name: "BOT",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:07:47 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 33,
    country: "Tanzania",
    name: "SMS",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:08:02 PM",
    user: "isaac@aimfirms.com",
    actions: ["view", "edit", "delete"],
  },
  {
    id: 34,
    country: "Tanzania",
    name: "Verify",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:08:19 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 35,
    country: "Tanzania",
    name: "WorldRemit",
    status: "Active",
    mno: null,
    date_created: "8/20/2019 2:08:35 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 36,
    country: "Tanzania",
    name: "STARTIMES",
    status: "Active",
    mno: null,
    date_created: "8/20/2019 2:08:59 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 37,
    country: "Tanzania",
    name: "DStv",
    status: "Active",
    mno: null,
    date_created: "8/20/2019 2:09:38 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 38,
    country: "Tanzania",
    name: "Microsoft",
    status: "Active",
    mno: null,
    date_created: "8/20/2019 2:09:53 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 39,
    country: "Tanzania",
    name: "Ericsson",
    status: "Active",
    mno: null,
    date_created: "8/20/2019 2:10:28 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 40,
    country: "Tanzania",
    name: "Anna",
    status: "Inactive",
    mno: null,
    date_created: "8/20/2019 2:11:11 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 41,
    country: "Tanzania",
    name: "KOTAKB",
    status: "Inactive",
    mno: null,
    date_created: "8/20/2019 2:11:32 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 42,
    country: "Tanzania",
    name: "MAXCOM",
    status: "Active",
    mno: null,
    date_created: "8/20/2019 2:11:49 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 43,
    country: "Tanzania",
    name: "ABCTRANS",
    status: "Inactive",
    mno: null,
    date_created: "8/20/2019 2:12:04 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 44,
    country: "Tanzania",
    name: "CATEHOTELS",
    status: "Active",
    mno: null,
    date_created: "8/20/2019 2:12:21 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 45,
    country: "Tanzania",
    name: "SPENN",
    status: "Active",
    mno: null,
    date_created: "8/20/2019 2:12:41 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 46,
    country: "Tanzania",
    name: "CBABANK",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/20/2019 2:13:02 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 47,
    country: "Tanzania",
    name: "Moisha",
    status: "Active",
    mno: null,
    date_created: "8/27/2019 12:13:48 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 48,
    country: "Tanzania",
    name: "Google",
    status: "Active",
    mno: null,
    date_created: "8/27/2019 6:44:31 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 49,
    country: "Tanzania",
    name: "SHEREHE",
    status: "Active",
    mno: null,
    date_created: "5/4/2020 12:04:29 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 50,
    country: "Tanzania",
    name: "Send-Off",
    status: "Active",
    mno: null,
    date_created: "5/4/2020 12:04:45 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 51,
    country: "Tanzania",
    name: "HARUSI",
    status: "Active",
    mno: null,
    date_created: "5/4/2020 12:05:02 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 52,
    country: "Tanzania",
    name: "Msiba",
    status: "Active",
    mno: null,
    date_created: "5/4/2020 12:05:20 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 53,
    country: "Tanzania",
    name: "EVENT",
    status: "Active",
    mno: null,
    date_created: "5/4/2020 12:05:33 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 54,
    country: "Tanzania",
    name: "VSMS",
    status: "Active",
    mno: null,
    date_created: "6/5/2020 9:11:48 AM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 55,
    country: "Tanzania",
    name: "Facebook",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "6/22/2020 4:43:22 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 56,
    country: "Tanzania",
    name: "Whatsapp",
    status: "Active",
    mno: null,
    date_created: "6/22/2020 4:43:32 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 57,
    country: "Tanzania",
    name: "Uber",
    status: "Active",
    mno: null,
    date_created: "6/22/2020 4:43:45 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 58,
    country: "Tanzania",
    name: "Twitter",
    status: "Active",
    mno: null,
    date_created: "6/22/2020 4:43:56 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 59,
    country: "Tanzania",
    name: "Yahoo",
    status: "Active",
    mno: null,
    date_created: "6/22/2020 4:46:01 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 60,
    country: "Tanzania",
    name: "Multichoice",
    status: "Active",
    mno: null,
    date_created: "7/8/2020 2:53:06 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 61,
    country: "Tanzania",
    name: "CRDBBANK",
    status: "Inactive",
    mno: null,
    date_created: "7/8/2020 2:53:18 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 62,
    country: "Tanzania",
    name: "MAENDELEO",
    status: "Active",
    mno: null,
    date_created: "7/17/2020 1:18:35 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 63,
    country: "Tanzania",
    name: "Care TZ",
    status: "Active",
    mno: null,
    date_created: "7/17/2020 1:18:54 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 64,
    country: "Tanzania",
    name: "DUMA",
    status: "Active",
    mno: null,
    date_created: "7/17/2020 1:19:15 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 65,
    country: "Tanzania",
    name: "SILVER",
    status: "Active",
    mno: null,
    date_created: "7/17/2020 1:20:21 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 66,
    country: "Tanzania",
    name: "PEDIMA",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "8/5/2020 3:59:55 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 67,
    country: "Tanzania",
    name: "SADAKA",
    status: "Active",
    mno: null,
    date_created: "10/30/2020 3:54:53 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 68,
    country: "Tanzania",
    name: "JUMUIYA",
    status: "Active",
    mno: null,
    date_created: "10/30/2020 3:56:53 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 69,
    country: "Tanzania",
    name: "AHADI",
    status: "Active",
    mno: null,
    date_created: "10/30/2020 3:57:41 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 70,
    country: "Tanzania",
    name: "UWAKA",
    status: "Active",
    mno: null,
    date_created: "10/30/2020 3:58:20 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 71,
    country: "Tanzania",
    name: "WAWATA",
    status: "Active",
    mno: null,
    date_created: "10/30/2020 4:00:12 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 72,
    country: "Tanzania",
    name: "KWAYA",
    status: "Active",
    mno: null,
    date_created: "10/30/2020 4:01:28 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 73,
    country: "Tanzania",
    name: "PAROKIA",
    status: "Active",
    mno: null,
    date_created: "10/30/2020 4:10:44 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 74,
    country: "Tanzania",
    name: "JIMBO",
    status: "Active",
    mno: null,
    date_created: "10/30/2020 4:11:05 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 75,
    country: "Tanzania",
    name: "KIGANGO",
    status: "Active",
    mno: null,
    date_created: "10/30/2020 4:11:58 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 76,
    country: "Tanzania",
    name: "ZAKA",
    status: "Active",
    mno: null,
    date_created: "10/30/2020 4:12:22 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 77,
    country: "Tanzania",
    name: "ROZALI",
    status: "Active",
    mno: null,
    date_created: "10/30/2020 4:13:29 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 78,
    country: "Tanzania",
    name: "BIBLIA",
    status: "Active",
    mno: null,
    date_created: "10/30/2020 4:15:40 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 79,
    country: "Tanzania",
    name: "IBADA",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/30/2020 4:16:39 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 80,
    country: "Tanzania",
    name: "SOMO",
    status: "Active",
    mno: null,
    date_created: "10/30/2020 4:17:08 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 81,
    country: "Tanzania",
    name: "MTOTO YESU",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/30/2020 4:17:54 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 82,
    country: "Tanzania",
    name: "MAVUNO",
    status: "Active",
    mno: null,
    date_created: "10/30/2020 4:18:24 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 83,
    country: "Tanzania",
    name: "47 Music",
    status: "Active",
    mno: null,
    date_created: "12/16/2020 5:31:50 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 84,
    country: "Tanzania",
    name: "47 Chefs",
    status: "Active",
    mno: null,
    date_created: "12/16/2020 5:32:03 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 85,
    country: "Tanzania",
    name: "Ze Fox",
    status: "Active",
    mno: null,
    date_created: "12/16/2020 5:32:18 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 86,
    country: "Tanzania",
    name: "InArusha",
    status: "Active",
    mno: null,
    date_created: "12/16/2020 5:32:35 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 87,
    country: "Tanzania",
    name: "MBEZI TILES",
    status: "Active",
    mno: null,
    date_created: "12/16/2020 5:33:02 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 88,
    country: "Tanzania",
    name: "Feitango",
    status: "Active",
    mno: null,
    date_created: "2/25/2021 1:53:59 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 89,
    country: "Tanzania",
    name: "KwanzaHomes",
    status: "Active",
    mno: null,
    date_created: "2/25/2021 1:54:13 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 90,
    country: "Tanzania",
    name: "IBB",
    status: "Active",
    mno: null,
    date_created: "2/25/2021 1:54:23 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 91,
    country: "Tanzania",
    name: "Maboto",
    status: "Active",
    mno: null,
    date_created: "2/25/2021 2:00:21 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 92,
    country: "Tanzania",
    name: "PHYSIOCARE",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "4/16/2021 1:15:43 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 93,
    country: "Tanzania",
    name: "MC KIJUKUU",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "4/16/2021 1:16:11 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 94,
    country: "Tanzania",
    name: "InfoSMS",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "4/22/2021 12:59:50 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 95,
    country: "Tanzania",
    name: "Wisepill",
    status: "Inactive",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "4/22/2021 1:37:56 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 96,
    country: "Tanzania",
    name: "Recharge",
    status: "Inactive",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "4/22/2021 1:44:56 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 97,
    country: "Tanzania",
    name: "Assemble",
    status: "Active",
    mno: null,
    date_created: "5/21/2021 6:50:04 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 98,
    country: "Tanzania",
    name: "MEDINA",
    status: "Active",
    mno: null,
    date_created: "5/21/2021 6:50:33 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 99,
    country: "Tanzania",
    name: "Lete",
    status: "Active",
    mno: null,
    date_created: "7/28/2021 10:58:18 AM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 101,
    country: "Tanzania",
    name: "Kopa Ada",
    status: "Active",
    mno: null,
    date_created: "8/25/2021 2:59:23 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 102,
    country: "Tanzania",
    name: "NDASHA PUB",
    status: "Active",
    mno: null,
    date_created: "8/26/2021 10:31:13 AM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 103,
    country: "Tanzania",
    name: "TIGER",
    status: "Active",
    mno: null,
    date_created: "8/26/2021 10:31:53 AM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 104,
    country: "Tanzania",
    name: "FK.Academy",
    status: "Active",
    mno: null,
    date_created: "9/16/2021 4:45:09 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 105,
    country: "Tanzania",
    name: "Marathon",
    status: "Active",
    mno: null,
    date_created: "9/16/2021 4:45:41 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 106,
    country: "Tanzania",
    name: "Tamasha",
    status: "Active",
    mno: null,
    date_created: "9/16/2021 4:46:05 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 107,
    country: "Tanzania",
    name: "Mnada",
    status: "Active",
    mno: null,
    date_created: "9/16/2021 4:46:30 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 108,
    country: "Tanzania",
    name: "Bonanza",
    status: "Active",
    mno: null,
    date_created: "9/16/2021 4:46:54 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 109,
    country: "Tanzania",
    name: "TikTok",
    status: "Active",
    mno: null,
    date_created: "10/11/2021 2:35:40 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 110,
    country: "Tanzania",
    name: "LetsChat",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:36:07 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 111,
    country: "Tanzania",
    name: "LIKE",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:36:51 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 112,
    country: "Tanzania",
    name: "RadioMaria",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:37:17 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 113,
    country: "Tanzania",
    name: "IMO",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:37:33 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 114,
    country: "Tanzania",
    name: "Telegram",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:37:54 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 115,
    country: "Tanzania",
    name: "Bolt",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:38:15 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 116,
    country: "Tanzania",
    name: "CloudOTP",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:38:40 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 117,
    country: "Tanzania",
    name: "Samsung",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:39:01 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 118,
    country: "Tanzania",
    name: "Spotify",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:39:21 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 119,
    country: "Tanzania",
    name: "PLVSMS",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:39:43 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 120,
    country: "Tanzania",
    name: "NOTICE",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:40:06 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 121,
    country: "Tanzania",
    name: "AIRBNB",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:40:28 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 122,
    country: "Tanzania",
    name: "Phone Code",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:41:04 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 123,
    country: "Tanzania",
    name: "SMSAuth",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:41:45 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 124,
    country: "Tanzania",
    name: "ATHOC",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:42:04 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 125,
    country: "Tanzania",
    name: "Booking",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:42:28 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 126,
    country: "Tanzania",
    name: "Viber",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:42:52 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 127,
    country: "Tanzania",
    name: "Binance",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:43:23 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 128,
    country: "Tanzania",
    name: "iATSMS",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:43:58 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 129,
    country: "Tanzania",
    name: "VKcom",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:44:16 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 130,
    country: "Tanzania",
    name: "Zoom",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:44:33 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 131,
    country: "Tanzania",
    name: "MARKET",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:44:54 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 132,
    country: "Tanzania",
    name: "SMSPORTAL",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:45:18 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 133,
    country: "Tanzania",
    name: "UoPeople",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:45:48 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 134,
    country: "Tanzania",
    name: "Remitly",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:46:26 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 135,
    country: "Tanzania",
    name: "PayAsYouGo",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:47:04 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 136,
    country: "Tanzania",
    name: "AxisBk",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:47:31 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 137,
    country: "Tanzania",
    name: "PureHealth",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:48:04 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 138,
    country: "Tanzania",
    name: "ClickSend",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:48:31 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 139,
    country: "Tanzania",
    name: "TBAUTH",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:49:10 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 140,
    country: "Tanzania",
    name: "taobao",
    status: "Active",
    mno: null,
    date_created: "10/11/2021 2:49:57 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 141,
    country: "Tanzania",
    name: "DTBT",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:51:36 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 142,
    country: "Tanzania",
    name: "Apple",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:51:58 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 143,
    country: "Tanzania",
    name: "Tinder",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:52:20 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 144,
    country: "Tanzania",
    name: "Snapchat",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:52:43 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 145,
    country: "Tanzania",
    name: "CamScanner",
    status: "Active",
    mno: null,
    date_created: "10/11/2021 2:53:10 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 146,
    country: "Tanzania",
    name: "Xiaomi",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:53:32 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 147,
    country: "Tanzania",
    name: "AliSMS",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:54:02 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 148,
    country: "Tanzania",
    name: "HUAWEI",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:54:23 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 149,
    country: "Tanzania",
    name: "DISCORD",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:54:45 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 150,
    country: "Tanzania",
    name: "LiteForex",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:55:09 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 151,
    country: "Tanzania",
    name: "TradingView",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:55:52 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 152,
    country: "Tanzania",
    name: "BancABC",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:56:44 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 153,
    country: "Tanzania",
    name: "Indriver",
    status: "Inactive",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:57:40 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 154,
    country: "Tanzania",
    name: "KakaoTalk",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 2:58:38 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 155,
    country: "Tanzania",
    name: "HPAccount",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 3:00:33 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 156,
    country: "Tanzania",
    name: "BOOKING.COM",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 3:02:54 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 157,
    country: "Tanzania",
    name: "Proton",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 3:04:00 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 158,
    country: "Tanzania",
    name: "peekvision",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/11/2021 3:05:46 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 159,
    country: "Tanzania",
    name: "Protcn",
    status: "Active",
    mno: null,
    date_created: "10/11/2021 3:07:24 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 160,
    country: "Tanzania",
    name: "CLUBHOUSE",
    status: "Active",
    mno: null,
    date_created: "10/11/2021 3:08:37 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 161,
    country: "Tanzania",
    name: "BIGO",
    status: "Active",
    mno: null,
    date_created: "10/11/2021 3:09:53 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 162,
    country: "Tanzania",
    name: "WISE",
    status: "Active",
    mno: null,
    date_created: "10/11/2021 3:12:30 PM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 163,
    country: "Tanzania",
    name: "MUDA",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/25/2021 9:55:21 AM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 164,
    country: "Tanzania",
    name: "DOZI",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "10/25/2021 9:55:40 AM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 165,
    country: "Tanzania",
    name: "betPawa",
    status: "Active",
    mno: null,
    date_created: "10/25/2021 5:47:15 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 166,
    country: "Tanzania",
    name: "Apostolic",
    status: "Active",
    mno: null,
    date_created: "10/25/2021 5:47:30 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 167,
    country: "Tanzania",
    name: "UCHUMI BANK",
    status: "Active",
    mno: null,
    date_created: "10/25/2021 5:47:45 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 168,
    country: "Tanzania",
    name: "Mkombozi",
    status: "Active",
    mno: null,
    date_created: "10/25/2021 5:47:57 PM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 169,
    country: "Tanzania",
    name: "LandSelerlv",
    status: "Active",
    mno: null,
    date_created: "11/10/2021 9:08:25 AM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 170,
    country: "Tanzania",
    name: "yassir",
    status: "Active",
    mno: null,
    date_created: "11/10/2021 9:11:32 AM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 171,
    country: "Tanzania",
    name: "SDAKurasini",
    status: "Active",
    mno: null,
    date_created: "11/18/2021 10:49:30 AM",
    user: "isaac@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 172,
    country: "Tanzania",
    name: "HakiElimu",
    status: "Active",
    mno: null,
    date_created: "12/8/2021 10:36:44 AM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 173,
    country: "Tanzania",
    name: "MWENDO",
    status: "Active",
    mno: null,
    date_created: "12/17/2021 9:57:46 AM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 174,
    country: "Tanzania",
    name: "Easybuy",
    status: "Active",
    mno: null,
    date_created: "1/21/2022 11:16:37 AM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 175,
    country: "Tanzania",
    name: "Palmcredit",
    status: "Active",
    mno: [
      { name: "Vodacom", status: "Registered" },
      { name: "Zantel", status: "Registered" },
      { name: "Halotel", status: "Registered" },
      { name: "Airtel", status: "Registered" },
      { name: "Tigo", status: "Not Allowed" },
      { name: "TTCL", status: "Pending" },
      { name: "Smile", status: "Pending" },
    ],
    date_created: "2/2/2022 10:18:47 AM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 176,
    country: "Tanzania",
    name: "LetsLoan",
    status: "Active",
    mno: null,
    date_created: "2/2/2022 10:19:26 AM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
  {
    id: 177,
    country: "Tanzania",
    name: "Mafanikio",
    status: "Active",
    mno: null,
    date_created: "2/2/2022 10:19:43 AM",
    user: "marcela@aimfirms.com",
    actions: ["edit", "delete"],
  },
];

export default sid_data;
